// For other properties check:
// https://schema.org/ImageObject
export default (url, width, height, props) => {
  const imageObject = {
    '@type': `ImageObject`,
    url
  }

  if (width) {
    imageObject.width = width
  }

  if (height) {
    imageObject.height = height
  }

  return props ? Object.assign(imageObject, props) : imageObject
}
