import { useStaticQuery, graphql } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query Navigation {
      allContentfulNavigation {
        edges {
          node {
            item {
              label
              order
              url
              image {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `).allContentfulNavigation.edges[0].node.item.sort((a, b) => a.order - b.order)
