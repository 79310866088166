// For other properties check:
// https://schema.org/ItemList
export default (name, numberOfItems) => {
  const itemList = {
    '@context': `https://schema.org`,
    '@type': `ItemList`,
    name,
    itemListElement: []
  }

  if (numberOfItems !== undefined) {
    itemList.numberOfItems = numberOfItems
  }

  return itemList
}
