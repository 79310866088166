import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  margin: 0 0 -${({ theme }) => theme.verticalRythm[7]} 0;
  overflow-x: scroll;
  white-space: nowrap;
  padding: ${({ theme }) => theme.verticalRythm[7]} ${({ theme }) => theme.verticalRythm[7]} ${({ theme }) => theme.verticalRythm[8]} ${({ theme }) => theme.verticalRythm[7]};
  text-align: center;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
`

export default ({ children }) => <Content>{children}</Content>
