import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  return useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            defaultBanner: banner
            author
            siteLanguage
            ogLanguage
            twitterUsername
            facebookUsername
            facebookUrl
            twitterUrl
            linkedinUrl
            linkedinUsername
            instagramUsername
            siteUrl
            siteName
            siteNameAlternative
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
            consultationLanguage
            telephone
            contactType
            contactOption
            areaServed
            authorName
            authorSameAs
            longitude
            latitude
            openingHours
            priceRange
            hasMap
            accountingServiceSameAs
            ratingValue
            reviewCount
          }
        }
      }
    `
  )
}
