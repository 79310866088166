import React from 'react'
import styled from 'styled-components'
import { Menu } from 'styled-icons/boxicons-regular'
import { Close } from 'styled-icons/remix-line/Close'

const Toggle = styled.button`
  position: relative;
  display: flex;
  z-index: ${({ theme }) => theme.zIndeces.higher};
  margin: 0;
  padding: ${({ theme }) => theme.verticalRythm[7]};
  font-size: ${({ theme }) => theme.fontSizes[7]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.gray[900]};
  background-color: #fff;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radii[1]};
  transition: color ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default},
              background-color ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default},
              box-shadow ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    outline: none;
  }
`

const MenuIcon = styled(Menu)`
  margin: 0 0 0 ${({ theme }) => theme.verticalRythm[4]};
  width: ${({ theme }) => theme.fontSizes[8]};
  height: ${({ theme }) => theme.fontSizes[8]};
`

const CloseIcon = styled(Close)`
  margin: 0 0 0 ${({ theme }) => theme.verticalRythm[4]};
  width: ${({ theme }) => theme.fontSizes[8]};
  height: ${({ theme }) => theme.fontSizes[8]};
`

export default ({ onClick, isOpen }) => (
  <Toggle onClick={onClick}>
    Menu
    {
      isOpen ?
      <CloseIcon /> :
      <MenuIcon />
    }
  </Toggle>
)
