import React from 'react'
import Icon from './promo-icon'
import Container from './promo-container'
import Content from './promo-content'
import PhoneLink from './promo-phone-link'

export default ({ promo: { text, phone, phoneLink } }) => (
  <Container>
    <PhoneLink phoneLink={phoneLink}>
      <Content>
        <Icon />
        <span>{text}</span>
        <br />
        <span>{phone}</span>
      </Content>
    </PhoneLink>
  </Container>
)
