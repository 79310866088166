import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

const breakpoint = ({ theme }) => `${theme.media.md}`

export default createGlobalStyle`
  ${normalize()}

  @import url('https://rsms.me/inter/inter.css');

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  * {
    line-height: ${({ theme }) => theme.verticalRythm[9]};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.verticalRythm[9]};
  }

  html {
    margin: 0;
    font-size: 100%;
    line-height: 100%;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.fonts.defaultLegacy};
    box-sizing: border-box;

    ${breakpoint} {
      font-size: 125%;
      line-height: 125%;
    }
  }

  @supports (font-variation-settings: normal) {
    html {
      font-family: ${({ theme }) => theme.fonts.default};
      font-feature-settings: "tnum", "zero", "frac", "cpsp", "salt", "ss02";
    }
  }

  body {
    box-sizing: inherit;
    font-size: inherit;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${({ theme }) => theme.colors.primary.default};
    transition: color ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    margin: 0;
  }
`
