// For other properties check:
// https://schema.org/AccountingService
export default (id, name, address, hasmap, sameAs, image, logo, description, geo, openingHours, url, priceRange, telephone, props) => {
  const organization = {
    '@context': `https://schema.org`,
    '@type': `LocalBusiness`,
    '@id': id,
    name,
    address,
    hasmap,
    sameAs,
    image,
    logo,
    description,
    geo,
    openingHours,
    url,
    priceRange,
    telephone
  }

  return props ? Object.assign(organization, props) : organization
}
