import scale from './functions/modular-scale'
import rythm from './functions/rythm'

const unit = `rem`
const ratio = 1.4
const min = -7
const max = 8
const precision = 1000
const breakpointPrecision = `0.001rem`

const fontSizes = scale(ratio, min, max, precision, unit)

const verticalRythm = rythm(ratio, min, max, precision, unit)

const breakpoints = [`320px`, `480px`, `920px`, `1100px`, `1400px`]

const containers = [`800px`]

const media = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
}

// low, lower, xlow, 2xlow
// high, higher, xhigh, 2xhigh
const zIndeces = {
  lowest: -3000,
  lower: -2000,
  low: -1000,
  md: 0,
  high: 1000,
  higher: 2000,
  highest: 3000,
}

const colors = {
  body: `#fff`,
  text: `#111`,
  primary: {
    light: `#8ed1fc`,
    default: `#2d9bea`,
    dark: `#0069b5`,
  },
  gray: {
    50: `#fafafa`,
    100: `#f5f5f5`,
    200: `#eeeeee`,
    300: `#e0e0e0`,
    400: `#bdbdbd`,
    500: `#9e9e9e`,
    600: `#757575`,
    700: `#616161`,
    800: `#424242`,
    900: `#212121`,
  },
}

const transitions = {
  default: `cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
}

const timing = {
  fast: '200ms',
  medium: '300ms',
  long: '600ms',
}

const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 900,
}

const fonts = {
  default: `"Inter var", system-ui, sans-serif`,
  defaultLegacy: `"Inter", system-ui, sans-serif`,
}

const letterSpacings = {
  tight: `-0.05em`,
  normal: `normal`,
  caps: `0.25em`,
  labels: `0.05em`,
}

const radii = [`0`, `2px`, `4px`, `8px`, `16px`]

const borderWidth = [`0`, `2px`, `4px`, `8px`, `16px`]

export default {
  borderWidth,
  fontSizes,
  verticalRythm,
  breakpoints,
  colors,
  fonts,
  fontWeights,
  letterSpacings,
  radii,
  timing,
  transitions,
  media,
  zIndeces,
  containers,
  breakpointPrecision
}
