// For other properties check:
// https://schema.org/Offer
export default (priceCurrency, price, seller) => {
  const nextYear = new Date()
  nextYear.setFullYear(nextYear.getFullYear() + 1)

  return {
    '@context': `http://schema.org/`,
    '@type': `Offer`,
    priceCurrency,
    price,
    seller,
    priceValidUntil: nextYear.toISOString().split('T')[0],
    availability: "https://schema.org/InStock",
  }
}