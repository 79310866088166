import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'

const Item = styled(posed.li({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 200,
      ease: 'easeIn'
    }
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 200,
      ease: 'easeOut'
    }
  }
}))`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
`

export default ({ children, className }) => {
  return (
    <Item className={className}>
      {children}
    </Item>
  )
}
