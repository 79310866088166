import React from 'react'
import { Helmet } from 'react-helmet'
 
export default ({ name, lang, url, type, title, description, img }) => {
  return (
    <Helmet>
      <meta property="og:site_name" content={name} />
      <meta property="og:locale" content={lang} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      {/* Add og:image:height & og:image:width */}
      <meta property="og:image:alt" content={description} />
      <meta property="fb:app_id" content={`261532987634023`} />
    </Helmet>
  )
}
