import React from 'react'
import styled from 'styled-components'

const PhoneLink = styled.a`
  position: relative;
  margin: 0;
  height: ${({ theme }) => theme.verticalRythm[10]};
  z-index: ${({ theme }) => theme.zIndeces.higher};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.verticalRythm[6]};
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: ${({ theme }) => theme.fontSizes[9]};
  text-decoration: none;
  overflow: hidden;
`

export default ({ children, phoneLink }) => <PhoneLink href={`tel:${phoneLink}`}>{children}</PhoneLink>
