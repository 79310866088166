export default (ratio, min, max, precision, unit) => {
  const arr = [0]

  for (let i = min; i < max; i++) {
    const num = Math.pow(ratio, i)
    const rounded = Math.round(num * precision) / precision
    arr.push(rounded)
  }

  return arr.map((i) => `${i}${unit}`)
}
