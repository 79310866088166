import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import GlobalStyles from './styles/global'
import usePromoData from '../hooks/use-promo-data'
import useNavigationData from '../hooks/use-navigation-data'
import Header from './header'
import Footer from './footer'
import Seo from './seo'

const Main = styled.main`
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.verticalRythm[9]};
  max-width: ${({ theme }) => theme.containers[0]};
`

export default ({ children, customSeo }) => {
  const { contentfulPhonePromo } = usePromoData()
  const navigation = useNavigationData()

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {!customSeo && <Seo />}
        <Header promo={contentfulPhonePromo} nav={navigation} />
        <Main>{children}</Main>
        <Footer />
      </>
    </ThemeProvider>
  )
}
