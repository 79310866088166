// For other properties check:
// https://schema.org/PostalAddress
export default (streetAddress, addressLocality, addressRegion, postalCode, addressCountry, props) => {
  const postalAddress = {
    '@type': `PostalAddress`,
    streetAddress,
    addressLocality,
    addressRegion,
    postalCode,
    addressCountry
  }

  return props ? Object.assign(postalAddress, props) : postalAddress
}
