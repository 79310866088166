// For other properties check:
// https://schema.org/ListItem
export default (id, url, headline, mainEntityOfPage, publisher, copyrightHolder, creator, props) => {
  const contactPage = {
    '@context': `http://schema.org`,
    '@type': `ContactPage`,
    '@id': id,
    url,
    headline,
    mainEntityOfPage,
    publisher,
    copyrightHolder,
    creator
  }

  return props ? Object.assign(contactPage, props) : contactPage
}
