import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import useNavigationData from '../hooks/use-navigation-data'

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: ${({ theme }) => theme.verticalRythm[10]};
  z-index: ${({ theme }) => theme.zIndeces.higher};
`

const Image = styled(Img)`
  width: ${({ theme }) => theme.verticalRythm[9]};
  margin: 0;

  div,
  img {
    margin: 0;
  }
`

export default () => {
  const logo = useNavigationData()[0].image.fluid

  return (
    <LogoLink to="/" title={`San Diego CPA logo`}>
      <Image
        fluid={ logo }
        alt={`San Diego CPA logo`}
      />
    </LogoLink>
  )
}
