import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const NavLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes[11]};
  line-height: ${({ theme }) => theme.verticalRythm[11]};
  margin: 0 0 ${({ theme }) => theme.verticalRythm[9]} 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: color ${({ theme }) => theme.timing.medium} ${({ theme }) => theme.transitions.default};

  @media screen and (max-height: 500px) {
    margin: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }

  &.active {
    text-decoration: underline;
  }
`

export default ({ children, className, label, to, onClick, index }) => {
  return (
    <>
      {
        index === 0 ?
        <NavLink
          partiallyActive={false}
          to={to}
          onClick={onClick}
          activeClassName="active"
          className={className}
          title={label}
        >
          {children}
        </NavLink> :
        <NavLink
          partiallyActive={true}
          to={to}
          onClick={onClick}
          activeClassName="active"
          className={className}
          title={label}
        >
          {children}
        </NavLink>
      }
    </>
  )
}

