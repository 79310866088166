import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'

const List = styled(posed.ul({
  open: {
    delayChildren: 400,
    staggerChildren: 200,
  },
}))`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  touch-action: auto;
`

export default ({ children, className }) => (
  <List className={className}>
    {children}
  </List>
)
