import React from 'react'
import { Helmet } from 'react-helmet'
 
export default ({ handle, title, description, img }) => {
  return (
    <Helmet>
      <meta name="twitter:card" content={'summary_large_image'} />
      <meta name="twitter:site" content={handle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:image:alt" content={description} />
    </Helmet>
  )
}
