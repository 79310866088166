import React from 'react'
import styled from 'styled-components'

const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  margin: 0 0 ${({ theme }) => theme.verticalRythm[9]} 0;
  hyphens: auto;
`

export default ({ children, className }) => <Paragraph className={className}>{children}</Paragraph>
