import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import Twitter from './seo-twitter'
import OG from './seo-og'
import imageObject from './seo-schema-image-object'
import article from './seo-schema-article'
import contactPageSchema from './seo-schema-contact-page'
import faqPageSchema from './seo-schema-faq-page'
import localBusiness from './seo-schema-local-business'
import service from './seo-schema-service'
import postalAddress from './seo-schema-postal-address'
import referenceNode from './seo-schema-reference-node'
import organization from './seo-schema-organization'
// import person from './seo-schema-person'
import geoCoordinates from './seo-schema-geo-coordinates'
import answer from './seo-schema-answer'
import question from './seo-schema-question'
import breadcrumbList from './seo-schema-breadcrumb-list'
import listItem from './seo-schema-list-item'
import listItemUrl from './seo-schema-list-item-url'
import itemList from './seo-schema-item-list'
// import contactPoint from './seo-schema-contact-point'
// import webPage from './seo-schema-web-page'
// import offerCatalog from './seo-schema-offer-catalog'
// import product from './seo-schema-product'
import offer from './seo-schema-offer'

export default ({
  homePage = null,
  blogPage = null,
  blogPost = null,
  servicePage = null,
  servicePost = null,
  faqPage = null,
  contactPage = null,
}) => {
  const metadata = useSiteMetadata()

  const {
    site: {
      // buildTime,
      siteMetadata: {
        defaultTitle,
        defaultDescription,
        defaultBanner,
        siteUrl,
        siteName,
        siteLanguage,
        siteNameAlternative,
        twitterUsername,
        facebookUsername,
        // linkedinUsername,
        // instagramUsername,
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry,
        // consultationLanguage,
        telephone,
        // contactType,
        // contactOption,
        // areaServed,
        author,
        // authorName,
        // authorSameAs,
        longitude,
        latitude,
        openingHours,
        priceRange,
        hasMap,
        organizationSameAs,
        // ratingValue,
        // reviewCount
      }
    }
  } = metadata

  const seo = {
    name: siteName,
    altName: siteNameAlternative,
    title: defaultTitle,
    description: defaultDescription,
    image: `${siteUrl}${defaultBanner}`,
    url: siteUrl,
    width: '1125px',
    height: '750px',
    twitter: twitterUsername,
    facebook: facebookUsername,
    lang: siteLanguage,
    author
  }

  let schemaContactPage = null
  let schemaOrganization = null
  let schemaArticle = null
  let schemaWebPage = null
  let schemaFaqPage = null
  let schemaLocalBusiness = null
  let schemaService = null
  let schemaBreadcrumb = null
  let schemaItemList = null
  let schemaProduct = null

  let twitterHandle = seo.twitter
  let twitterTitle = seo.title
  let twitterDescription = seo.description
  let twitterImg = `${siteUrl}${defaultBanner}`

  let ogName = seo.name
  let ogLang = seo.lang
  let ogUrl = siteUrl
  let ogType = `website`
  let ogTitle = seo.title
  let ogDescription = seo.description
  let ogImg = `${siteUrl}${defaultBanner}`

  const breadcrumbItemListElement = [listItem(1, siteUrl, `Homepage`)]

  if (homePage) {
    schemaOrganization = organization(
      seo.title,
      postalAddress(
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry
      ),
      organizationSameAs,
      // imageObject(`${seo.url}/banner.jpg`, `1125px`, `750px`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      seo.description,
      `${seo.url}`,
      telephone
    )
  }

  if (faqPage) {
    seo.title = faqPage.title
    seo.description = faqPage.body.childMarkdownRemark.excerpt

    const mainEntity = faqPage.faqList.map(item => question(item.question, answer(item.answer.childMarkdownRemark.html)))

    schemaFaqPage = faqPageSchema(
      `${siteUrl}#faq`,
      `${seo.url}${faqPage.path.url}/`,
      faqPage.title,
      mainEntity,
      referenceNode(`${seo.url}#organization`, `Organization`),
      referenceNode(`${seo.url}#organization`, `Organization`),
      referenceNode(`${seo.url}#organization`, `Organization`),
    )

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${faqPage.path.url}/`, faqPage.path.label))

    twitterTitle = faqPage.title
    twitterDescription = faqPage.body.childMarkdownRemark.excerpt

    ogUrl = `${seo.url}${faqPage.path.url}/`
    ogTitle = faqPage.title
    ogDescription = faqPage.body.childMarkdownRemark.excerpt
  }

  if (contactPage) {
    seo.title = contactPage.title
    seo.description = contactPage.body.childMarkdownRemark.excerpt

    schemaOrganization = organization(
      seo.title,
      postalAddress(
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry
      ),
      organizationSameAs,
      // imageObject(`${seo.url}/banner.jpg`, `1125px`, `750px`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      seo.description,
      `${seo.url}`,
      telephone
    )

    schemaContactPage = contactPageSchema(
      `${seo.url}#contact`,
      `${seo.url}${contactPage.path.url}/`,
      contactPage.title,
      referenceNode(`${seo.url}#contact`),
      referenceNode(`${seo.url}#organization`),
      referenceNode(`${seo.url}#organization`),
      referenceNode(`${seo.url}#organization`)
    )

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${contactPage.path.url}/`, contactPage.path.label))

    twitterTitle = contactPage.title
    twitterDescription = contactPage.body.childMarkdownRemark.excerpt

    ogUrl = `${seo.url}${contactPage.path.url}/`
    ogTitle = contactPage.title
    ogDescription = contactPage.body.childMarkdownRemark.excerpt
  }

  if (blogPage) {
    seo.title = blogPage.title
    seo.description = blogPage.description

    schemaItemList = itemList(blogPage.node.title, blogPage.list.edges.length)
    schemaItemList.itemListElement = blogPage.list.edges.map((item, i) => {
      return listItemUrl(i + 1, `${seo.url}${item.node.path.url}/${item.node.slug}`, item.node.title)
    })

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${blogPage.node.path.url}/`, blogPage.node.path.label))

    twitterTitle = blogPage.node.title
    twitterDescription = blogPage.node.body.childMarkdownRemark.excerpt

    ogUrl = `${seo.url}${blogPage.node.path.url}/`
    ogTitle = blogPage.node.title
    ogDescription = blogPage.node.body.childMarkdownRemark.excerpt
  }

  if (blogPost) {
    seo.title = blogPost.title
    seo.description = blogPost.body.childMarkdownRemark.excerpt
    seo.image = blogPost.image.fluid.src

    schemaOrganization = organization(
      seo.title,
      postalAddress(
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry
      ),
      organizationSameAs,
      // imageObject(`${seo.url}/banner.jpg`, `1125px`, `750px`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      seo.description,
      `${seo.url}`,
      telephone
    )

    schemaArticle = article(
      referenceNode(`${seo.url}#organization`, `Organization`),
      referenceNode(`${seo.url}#organization`, `Organization`),
      referenceNode(`${seo.url}#organization`, `Organization`),
      `${new Date().getFullYear()}`,
      blogPost.title,
      blogPost.body.childMarkdownRemark.excerpt,
      blogPost.createdAt,
      blogPost.updatedAt,
      `${seo.url}${blogPost.path.url}/${blogPost.slug}/`,
      imageObject(`https:${blogPost.image.fluid.src}`),
      `${seo.url}${blogPost.path.url}/${blogPost.slug}/`,
      seo.lang,
      referenceNode(`${seo.url}#organization`, `Organization`),
      // person(seo.author, authorSameAs)
    )

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${blogPost.path.url}/`, blogPost.path.label))
    breadcrumbItemListElement.push(listItem(3, `${seo.url}${blogPost.path.url}/${blogPost.slug}/`, blogPost.title))

    twitterTitle = blogPost.title
    twitterDescription = blogPost.body.childMarkdownRemark.excerpt
    twitterImg = `https:${blogPost.image.fluid.src}`

    ogUrl = `${seo.url}${blogPost.path.url}/${blogPost.slug}/`
    ogType = `article`
    ogTitle = blogPost.title
    ogDescription = blogPost.body.childMarkdownRemark.excerpt
    ogImg = `https:${blogPost.image.fluid.src}`
  }

  if (servicePage) {
    seo.title = servicePage.title
    seo.description = servicePage.body.childMarkdownRemark.excerpt

    // schemaService might be here

    schemaLocalBusiness = localBusiness(
      `${seo.url}#business`,
      servicePage.title,
      postalAddress(
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry
      ),
      hasMap,
      organizationSameAs,
      imageObject(`${seo.url}/banner.jpg`, `1125px`, `750px`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      servicePage.body.childMarkdownRemark.excerpt,
      geoCoordinates(longitude, latitude),
      openingHours,
      `${seo.url}${servicePage.path.url}/`,
      priceRange,
      telephone
    )

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${servicePage.path.url}/`, servicePage.path.label))

    schemaItemList = itemList(servicePage.title, servicePage.serviceList.length)
    schemaItemList.itemListElement = servicePage.serviceList.map((item, i) => {
      return listItemUrl(i + 1, `${seo.url}${servicePage.path.url}/${item.slug}`, item.service.title)
    })

    twitterTitle = servicePage.title
    twitterDescription = servicePage.body.childMarkdownRemark.excerpt

    ogUrl = `${seo.url}${servicePage.path.url}/`
    ogTitle = servicePage.title
    ogDescription = servicePage.body.childMarkdownRemark.excerpt
  }

  if (servicePost) {
    seo.title = servicePost.service.title
    seo.description = servicePost.service.body.childMarkdownRemark.excerpt

    schemaLocalBusiness = localBusiness(
      `${seo.url}#business`,
      servicePost.service.title,
      postalAddress(
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
        addressCountry
      ),
      hasMap,
      organizationSameAs,
      imageObject(`${seo.url}/banner.jpg`, `1125px`, `750px`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      servicePost.service.body.childMarkdownRemark.excerpt,
      geoCoordinates(longitude, latitude),
      openingHours,
      `${seo.url}${servicePost.service.path.url}/`,
      priceRange,
      telephone
    )

    schemaService = service(
      servicePost.service.title,
      referenceNode(`${seo.url}#business`, `LocalBusiness`),
      referenceNode(`${seo.url}#business`, `LocalBusiness`),
      imageObject(`${seo.url}/logo.jpg`, `252px`, `343px`),
      addressLocality,
      offer(
        `USD`,
        servicePost.service.price,
        referenceNode(`${seo.url}#business`, `LocalBusiness`),
      )
    )

    breadcrumbItemListElement.push(listItem(2, `${seo.url}${servicePost.service.path.url}/`, servicePost.service.path.label))
    breadcrumbItemListElement.push(listItem(3, `${seo.url}${servicePost.service.path.url}/${servicePost.slug}/`, servicePost.service.title))

    twitterTitle = servicePost.service.title
    twitterDescription = servicePost.service.body.childMarkdownRemark.excerpt

    ogUrl = `${seo.url}${servicePost.service.path.url}/`
    ogTitle = servicePost.service.title
    ogDescription = servicePost.service.body.childMarkdownRemark.excerpt
    ogImg = `${siteUrl}${defaultBanner}`
  }

  schemaBreadcrumb = breadcrumbList(breadcrumbItemListElement)

  return (
    <>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        {schemaProduct && <script id="schema-product" type="application/ld+json">{JSON.stringify(schemaProduct)}</script>}
        {schemaItemList && <script id="schema-item-list" type="application/ld+json">{JSON.stringify(schemaItemList)}</script>}
        {schemaOrganization && <script id="schema-organization" type="application/ld+json">{JSON.stringify(schemaOrganization)}</script>}
        {schemaBreadcrumb && <script id="schema-breadcrumb" type="application/ld+json">{JSON.stringify(schemaBreadcrumb)}</script>}
        {schemaArticle && <script id="schema-article" type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        {schemaLocalBusiness && <script id="schema-local-business" type="application/ld+json">{JSON.stringify(schemaLocalBusiness)}</script>}
        {schemaService && <script id="schema-service" type="application/ld+json">{JSON.stringify(schemaService)}</script>}
        {schemaWebPage && <script id="schema-web-page" type="application/ld+json">{JSON.stringify(schemaWebPage)}</script>}
        {schemaFaqPage && <script id="schema-faq-page" type="application/ld+json">{JSON.stringify(schemaFaqPage)}</script>}
        {schemaContactPage && <script id="schema-contact-page" type="application/ld+json">{JSON.stringify(schemaContactPage)}</script>}
      </Helmet>
      <Twitter
        handle={twitterHandle}
        title={twitterTitle}
        description={twitterDescription}
        img={twitterImg}
      />
      <OG
        name={ogName}
        lang={ogLang}
        url={ogUrl}
        type={ogType}
        title={ogTitle}
        description={ogDescription}
        img={ogImg}
      />
    </>
  )
}
