export default (ratio, min, max, precision, unit) => {
  const arr = []

  for (let i = min - 1; i < max; i++) {
    const index = Math.abs(i)
    let v = ratio * index
    i < 0 ? (v = Math.round((1 / v) * precision) / precision) : (v = Math.round(v * precision) / precision)

    arr.push(v)
  }

  return arr.sort().map((i) => `${i}${unit}`)
}
