// For other properties check:
// https://schema.org/BreadcrumbList
export default (id, type, props) => {
  const referenceNode = {
    '@id': id,
    '@type': type,
  }

  return props ? Object.assign(referenceNode, props) : referenceNode
}
