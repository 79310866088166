// For other properties check:
// https://schema.org/GeoCoordinates
export default (latitude, longitude, props) => {
  const geoCoordinates = {
    '@type': `GeoCoordinates`,
    latitude,
    longitude,
  }

  return props ? Object.assign(geoCoordinates, props) : geoCoordinates
}
