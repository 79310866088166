// For other properties check:
// https://schema.org/FAQPage
export default (id, url, headline, mainEntity, publisher, copyrightHolder, creator) => {
  return {
    '@context': `http://schema.org`,
    '@type': `FAQPage`,
    '@id': id,
    url,
    headline,
    mainEntity,
    publisher,
    copyrightHolder,
    creator,
  }
}
