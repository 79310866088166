// For other properties check:
// https://schema.org/WebPage
export default (copyrightHolder, creator, publisher, copyrightYear, headline, description, datePublished, dateModified, url, image, mainEntityOfPage, inLanguage, author, props) => {
  const article = {
    '@context': `http://schema.org`,
    '@type': `Article`,
    copyrightHolder: copyrightHolder,
    creator: creator,
    publisher: publisher,
    copyrightYear,
    headline,
    description,
    datePublished,
    dateModified,
    url,
    image,
    mainEntityOfPage,
    inLanguage,
    author
  }

  return props ? Object.assign(article, props) : article
}
