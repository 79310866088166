import React from 'react'
import styled from 'styled-components'

const Footer = styled.footer`
  text-align: center;
  padding: ${({ theme }) => theme.verticalRythm[8]} 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.text};
  background-color: #000;
  font-size: ${({ theme }) => theme.fontSizes[7]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
`

export default () => <Footer>© Copyright {new Date().getFullYear()}</Footer>
