// For other properties check:
// https://schema.org/AccountingService
export default (
  name,
  address,
  sameAs,
  //image,
  logo,
  description,
  url,
  telephone,
  props
) => {
  const organization = {
    '@context': `https://schema.org`,
    '@type': `Organization`,
    '@id': `${url}#organization`,
    name,
    address,
    sameAs,
    // image,
    logo,
    description,
    url,
    telephone
  }

  return props ? Object.assign(organization, props) : organization
}
