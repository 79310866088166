import styled from 'styled-components'
import { PhoneCall } from 'styled-icons/boxicons-solid/PhoneCall'

export default styled(PhoneCall)`
  margin: 0;
  display: inline;
  width: ${({ theme }) => theme.verticalRythm[9]};
  height: ${({ theme }) => theme.verticalRythm[9]};
  color: ${({ theme }) => theme.colors.primary.default};
`
