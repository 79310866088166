import React from 'react'
import styled from 'styled-components'

const Container = styled.nav`
  position: relative;
  display: flex;
  padding: ${({ theme }) => theme.verticalRythm[6]} ${({ theme }) => theme.verticalRythm[8]};
  margin: 0;
  justify-content: space-between;
`

export default ({ children }) => (
  <Container>
    {children}
  </Container>
)
