import React from 'react'
import styled from 'styled-components'
import Heading1 from './elements/heading1'

const PageHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: -2px;
  line-height: 1.2;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: radial-gradient(
      circle at top left,
      ${({ theme }) => theme.colors.primary.default},
      ${({ theme }) => theme.colors.primary.dark}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export default ({ children, className }) => <PageHeading className={className}>{children}</PageHeading>
