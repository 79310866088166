import React from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import posed from 'react-pose'
import List from './sidebar-list'
import Item from './sidebar-list-item'
import Link from './sidebar-link'

const Sidebar = styled(posed.div({
  open: {
    x: '0%',
    delayChildren: 200,
    staggerChildren: 200,
    transition: {
      duration: 200,
      ease: 'easeIn'
    }
  },
  closed: {
    x: 'calc(-100% - 15px)',
    delay: 200,
    transition: {
      duration: 200,
      ease: 'easeOut'
    }
  }
}))`
  position: fixed;
  margin: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndeces.high};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.verticalRythm[10]});
  background-color: #000;
  touch-action: none;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.95);
  display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
`

export default ({ isOpen, isHidden, className, nav }) => {
  const targetRef = React.createRef()
  const targetElement = targetRef.current

  const showTargetElement = () => disableBodyScroll(targetElement)
  const hideTargetElement = () => enableBodyScroll(targetElement)

  isOpen ? showTargetElement() : hideTargetElement()

  return (
    <Sidebar
      className={className}
      pose={isOpen ? 'open' : 'closed'}
      isHidden={isHidden}
    >
      <List>
        {nav.map(({ url, label }, index) => (
            <Item key={index}>
              <Link
                to={url}
                label={label}
                index={index}
              >
                {label}
              </Link>
            </Item>
          )
        )}
      </List>
    </Sidebar>
  )
}
