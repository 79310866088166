import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from './header-container'
import Button from './menu-button'
import Promo from './promo'
import Sidebar from './sidebar'
import Logo from './logo'

const Header = styled.div`
  top: 0;
  position: sticky;
  background-color: ${({ theme }) => theme.colors.gray[900]};
  z-index: ${({ theme }) => theme.zIndeces.higher};
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.95);
`

let isHidden = true

export default ({ promo, nav }) => {
  const [isOpen, setOpen] = useState(false)
  useEffect(() => { isHidden = false }, [])

  return (
    <Header>
      <Promo promo={promo} />
      <Container>
        <Logo />
        <Button
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        />
        <Sidebar
          nav={nav}
          isOpen={isOpen}
          isHidden={isHidden}
        />
      </Container>
    </Header>
  )
}
