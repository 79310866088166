// For other properties check:
// https://schema.org/ListItem
export default (position, url, name) => {
  return {
    '@type': `ListItem`,
    position,
    name,
    url
  }
}
