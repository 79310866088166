import { useStaticQuery, graphql } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query PhonePromo {
      contentfulPhonePromo {
        phoneLink
        phone
        text
      }
    }
  `)
