import React from 'react'
import styled from 'styled-components'
import styles from '../styles/heading'

const Heading1 = styled.h1`
  ${styles}

  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.verticalRythm[12]};
`

export default ({ children, className }) => <Heading1 className={className}>{children}</Heading1>
