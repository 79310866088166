// For other properties check:
// https://schema.org/Service
export default (serviceType, provider, brand, logo, city, offers) => {
  return {
    '@context': `http://schema.org/`,
    '@type': `Service`,
    serviceType,
    provider,
    brand,
    logo,
    areaServed: {
      '@type': `City`,
      name: city
    },
    offers
  }
}
